<template lang="pug">
  b-row
    b-col.mb-4.mb-xl-0(
      md='12'
      lg='12'
      xl='5')
      b-card.manager-firmwares-firmware-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body.d-flex.justify-content-start.align-items-center
          template(v-if='panelShow')
            .panel-picture.mr-4
              .wc-bubble.wc-bubble-md.wc-bubble-br.mr-3.shadow
                .wc-bubble-content.wc-bubble-linked.bg-primary
                  .i-wrapper
                    i.fal.fa-fw.fa-2x.fa-microchip.text-white

            .panel-info.d-flex.flex-column.flex-fill
              .panel-info-version.font-weight-bold
                | {{ panel.data.attributes.version }}

            .state.align-self-end
              b-badge.state.p-2(
                :variant="$wc.conf.states.firmware.variants[panel.data.attributes.state]"
                pill)
                i.fa-fw(:class="$wc.conf.states.firmware.icons[panel.data.attributes.state]")
                |  {{ $t('firmware.states.' + panel.data.attributes.state) }}
          template(v-else)
            wc-loaders-heading-avatar
    b-col.mb-4.mb-xl-0(
      md='12'
      lg='12'
      xl='4')
      b-card.manager-firmwares-firmware-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0(
                :title="$t('activerecord.models.firmware.one')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-conveyor-belt-alt
                span
                  | {{ $tc('machine.counts', panel.data.attributes.machines_count, { count: panel.data.attributes.machines_count } ) }}
          template(v-else)
            wc-loaders-list(:lines='5')
    b-col(
      md='12'
      lg='12'
      xl='3')
      b-card.manager-firmwares-firmware-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.shared.updated_at')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-check
                span
                  | {{ panel.data.attributes.updated_at }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.shared.created_at')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-plus
                span
                  | {{ panel.data.attributes.created_at }}
          template(v-else)
            wc-loaders-list(:lines='5')
</template>

<script>
import { Panel } from '@common/panel'
import WcLoadersHeadingAvatar from '@components/shared/loaders/WcLoadersHeadingAvatar'
import WcLoadersList from '@components/shared/loaders/WcLoadersList'

export default {
  name: 'manager-firmwares-firmware-panel',
  mixins: [Panel],
  components: {
    WcLoadersHeadingAvatar,
    WcLoadersList,
  },
  data() {
    return {
      cacheType: 'manager/firmwares/getPanel',
      cachePayload: { id: this.$route.params.firmware_id },
    }
  },
}
</script>
